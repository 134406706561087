<div class="container">
  <h1>{{'TOOLS.VAULT_CLEANER.NAME' | translate}}</h1>
  <p class="subtitle">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DESCRIPTION' | translate}} </p>

  <div class="top-info">
    <app-bungie-card (refresh)="refresh()" [refreshing]="!coreService.components[LoadingComponent.MANIFEST] || !coreService.components[LoadingComponent.INVENTORY] || reloading"></app-bungie-card>
  </div>

  <ng-container *ngIf="authenticated || useImportedData; else notLoggedIn">
    <ng-container  *ngIf="coreService.components[LoadingComponent.MANIFEST] && (coreService.components[LoadingComponent.INVENTORY] || useImportedData); else loading">
      <ng-container *ngIf="state === 'config'; then configTemplate"></ng-container>
      <ng-container *ngIf="state === 'sorting' && !backgroundSorting; then sortingTemplate"></ng-container>
      <ng-container *ngIf="state === 'executing'; then execTemplate"></ng-container>
      <ng-container *ngIf="state === 'locking'; then lockingTemplate"></ng-container>
      <ng-container *ngIf="state === 'done'; then doneTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>

<app-ad size="sideRail" bannerId="rail-vault" position="right" hideBreakpoint="900" #sideRail></app-ad>

<ng-template #configTemplate>

  <div class="vault">
    <div class="list-container vault-config">
      <div class="list-header"><h4>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONFIGURATION' | translate}}</h4><button class="clear-btn" (click)="loadDefaultConfig()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.RESET' | translate}}</button></div>
      <div class="config-warning show-small">
        {{'SECTIONS.VAULT_CLEANER.vault-cleaner.WARNING_RESPONSIVE' | translate}}
      </div>
      <div class="config"  *ngIf="!loadingVault">
        <h3 class="config-title"><span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CURRENT_USAGE' | translate}}</span><span>{{totalItems}}/700</span></h3>
        <div class="imported-user-info" *ngIf="useImportedData">
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.SEEING_VAULT_OF' | translate}} {{data.owner}}<span class="bng-code">#{{data.ownerCode}}</span><br>
          <a href="javascript:" (click)="useOwnVault()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.BACK_TO_OWN' | translate}}</a>
        </div>
        <div class="config-weapons">
          <h3>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.TITLE' | translate}}</h3>
          <ul class="option-list">
            <li><app-checkbox [(ngModel)]="config.weapons.execute">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.CLEAN' | translate}} ({{weapons}})</app-checkbox></li>
            <li>
              <ul class="option-list">
                <li><app-checkbox [(ngModel)]="config.weapons.groupArchetypes">{{('SECTIONS.VAULT_CLEANER.RULES.GROUP_ARCHETYPE') | translate}}</app-checkbox></li>
                <li><app-checkbox [(ngModel)]="config.weapons.groupElements">{{('SECTIONS.VAULT_CLEANER.RULES.GROUP_ELEMENTS') | translate}}</app-checkbox></li>
                <br>
                <ng-container *ngFor="let option of config.weapons.options">
                  <li *ngIf="!!option.id && !option.hidden && (!useImportedData || !option.dimSyncRequired)">
                    <app-checkbox [(ngModel)]="option.execute" [disabled]="!config.weapons.execute || (option.dimSyncRequired && !dimSyncAvailable)" (change)="option.onChange ? option.onChange(option) : null">{{('SECTIONS.VAULT_CLEANER.RULES.' + option.id) | translate}} <img title="This feature uses DIM Sync" alt="DIM sync required" *ngIf="option.dimSyncRequired" src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e"></app-checkbox>
                    <ng-container *ngIf="option.customization && option.execute">
                      <div class="custom-objective">
                        <app-value-slider
                          [formControl]="option.customization.formControl"
                          [min]="option.customization.options.sliderMin"
                          [max]="option.customization.options.sliderMax"
                          [snapMax]="'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.BEST_ONLY' | translate"
                          [snapMin]="'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.DONT_CHECK' | translate">
                        </app-value-slider>
                      </div>
                    </ng-container>
                  </li>
                  <br *ngIf="!option.id && !option.hidden">
                </ng-container>
              </ul>
            </li>
          </ul>
          <button class="filter-btn" (click)="showWeaponStep = !showWeaponStep"><fa-icon [icon]="['fas', showWeaponStep ? 'caret-down' : 'caret-right']"></fa-icon> {{'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.SELECT_TYPES' | translate}}</button>
          <div class="weapons-steps" [ngClass]="{'show': showWeaponStep}">
            <ul class="option-list">
              <ng-container *ngFor="let step of defaultSteps">
                <li *ngIf="step.category === 'weapon'"><app-checkbox [(ngModel)]="step.active">{{catagoryNames[step.type[0]]}} </app-checkbox></li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="config-armor">
          <h3>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_OPTIONS.TITLE' | translate}}</h3>
          <ul class="option-list">
            <li><app-checkbox [(ngModel)]="config.armor.execute">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_OPTIONS.CLEAN' | translate}} ({{armors}})</app-checkbox></li>
            <li>
              <ul class="option-list">
                <li><app-checkbox [(ngModel)]="config.armor.groupExotics">{{('SECTIONS.VAULT_CLEANER.RULES.GROUP_EXOTICS') | translate}}</app-checkbox></li>
                <li><app-checkbox [(ngModel)]="config.armor.groupByBuild">{{('SECTIONS.VAULT_CLEANER.RULES.GROUP_BY_BUILD') | translate}}</app-checkbox></li>
                <li><app-checkbox [(ngModel)]="config.armor.groupByArtifice">{{('SECTIONS.VAULT_CLEANER.RULES.GROUP_ARTIFICE') | translate}}</app-checkbox></li>
                <br>
                <ng-container *ngFor="let option of config.armor.options">
                  <div *ngIf="option.templateName === 'armorQualityHeader'" class="armor-quality-header">
                    <div class="toggle-ctn">
                      <div class="toggle-button" [class.selected]="armorTuningCheck === 'global'" (click)="armorTuningCheck = 'global'">{{'SECTIONS.VAULT_CLEANER.GLOBAL_QUALITY' | translate}}</div>
                      <div class="toggle-button" [class.selected]="armorTuningCheck === 'fine'" (click)="armorTuningCheck = 'fine'">{{'SECTIONS.VAULT_CLEANER.FINE_TUNING' | translate}}</div>
                    </div>
                    <div class="help-btn" (click)="openArmorGuide()"><fa-icon [icon]="['fas', 'book']"></fa-icon> {{'SECTIONS.VAULT_CLEANER.ARMOR_GUIDE' | translate}}</div>
                    <div class="armor-warning" *ngIf="armorTuningCheck === 'fine'">{{'SECTIONS.VAULT_CLEANER.POINTS_WARNING' | translate}}</div>
                  </div>
                  <li *ngIf="!!option.id && !option.hidden && (!useImportedData || !option.dimSyncRequired) && (!option.isActive || option.isActive())">
                    <app-checkbox [(ngModel)]="option.execute" [disabled]="!config.armor.execute || (option.dimSyncRequired && !dimSyncAvailable)" (change)="option.onChange ? option.onChange(option) : null">{{('SECTIONS.VAULT_CLEANER.RULES.' + option.id) | translate}} <img title="This feature uses DIM Sync" alt="DIM sync required" *ngIf="option.dimSyncRequired" src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e"></app-checkbox>
                    <ng-container *ngIf="option.customization && option.execute">
                      <div class="custom-objective" *ngIf="option.customization.type === 'number'">
                        <app-value-slider
                          [formControl]="option.customization.formControl"
                          [min]="option.customization.options.sliderMin"
                          [max]="option.customization.options.sliderMax"
                          [textOverride]="option.customization.override"
                          [snapMax]="'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.BEST_ONLY' | translate"
                          [snapMin]="'SECTIONS.VAULT_CLEANER.vault-cleaner.WEAPONS_OPTIONS.DONT_CHECK' | translate">
                        </app-value-slider>
                      </div>
                      <div class="custom-objective" *ngIf="option.customization.type === 'rating'">
                        <div class="toggle-ctn">
                          <div class="toggle-button"
                               (click)="option.customization.formControl.setValue(rating)"
                               [ngClass]="{'selected': option.customization.formControl.value === rating}"
                               *ngFor="let rating of option.customization.options.ratings">{{rating}}</div>
                        </div>
                      </div>
                    </ng-container>
                  </li>
                  <br *ngIf="!option.id && !option.hidden">
                </ng-container>
              </ul>
            </li>
          </ul>
        </div>
        <div class="dim-warning" *ngIf="!useImportedData && !dimSyncAvailable">
          <strong>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.SUPPORT' | translate}}</strong>
          <p>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.INFO' | translate}}</p>
          <p>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_SYNC.EXPLANATION' | translate}}.</p>
        </div>
        <div class="config-warning">
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONFIG_WARNING.1' | translate}}
          <ul>
            <li>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONFIG_WARNING.2' | translate}}</li>
            <li>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONFIG_WARNING.3' | translate}}</li>
          </ul>
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONFIG_WARNING.4' | translate}}
        </div>
        <div class="config-actions">
          <button class="app-btn" [disabled]="processing || (!config.weapons.execute && !config.armor.execute)" (click)="startSorting()" *ngIf="!useImportedData">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.REVIEW_MY_VAULT_BTN' | translate }}</button>
          <button class="app-btn" [disabled]="processing || (!config.weapons.execute && !config.armor.execute)" (click)="startSorting()" *ngIf="useImportedData">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.REVIEW_OTHERS_VAULT_BTN' | translate: { name: data.owner } }}</button>
        </div>
        <app-ad size="banner" bannerId="banner-vault-config"  [renderVisibleOnly]="true"></app-ad>
        <p class="help credits">
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.LAST_UPDATE' | translate}}{{ voltronService.version | date }}<br><br>
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.COMPILED_BY' | translate}}<br>
          <a href="https://www.reddit.com/user/Mercules904">u/Mercules904</a>,  <a href="https://www.reddit.com/user/pandapaxxy">u/pandapaxxy</a>, <a href="https://www.reddit.com/user/HavocsCall">u/HavocsCall</a> and <a href="https://blueberries.gg/">blueberries.gg</a>, with contributions from  <a href="https://twitter.com/chrisfried">&#64;chrisfried</a>, and  <a href="https://twitter.com/sundevour">&#64;sundevour</a><br>
          Thanks to <a href="https://twitter.com/48klocs">&#64;48klocs</a> for grouping all of those rolls in a single, easy to decode <a href="https://raw.githubusercontent.com/48klocs/dim-wish-list-sources/master/voltron.txt">file</a><br>
        </p>
        <div class="utility">
          <button class="share-btn" [title]="'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.SHARE_MY_VAULT_DESC' | translate" (click)="shareVault()" *ngIf="!useImportedData"> <fa-icon [icon]="['fas', 'share-alt']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.SHARE_MY_VAULT' | translate}}</button>
          <button class="share-btn" [title]="'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_CLEANUP_DESC' | translate" (click)="importCleanup()"> <fa-icon [icon]="['fas', 'snowplow']"></fa-icon> {{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_CLEANUP' | translate}}</button>
          <button class="share-btn" [title]="'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_VAULT_DESC' | translate" (click)="importVault()"> <fa-icon [icon]="['fas', 'upload']"></fa-icon>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_VAULT' | translate}}</button>
        </div>
        <ng-container *ngIf="false; else social"></ng-container>
      </div>
      <ng-container *ngIf="loadingVault">
        <div class="loader">
          <fa-icon [icon]="['fas', 'spinner']" animation="spin" size="lg"></fa-icon>
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.LOADING_INVENTORY' | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #sortingTemplate>
  <div class="vault-sorting-container">
    <app-side-menu [steps]="steps" [currentStepIndex]="currentStepIndex" [state]="state" [stepNames]="catagoryNames" (categoryChange)="goToStep($event)"></app-side-menu>
    <div class="list-container vault-sorting">
      <div class="list-header"><h4>{{processing ? 'Processing' : 'Review cleaning result'}}</h4></div>
      <div class="header">
        <button (click)="prevStep()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.BACK' | translate}} ({{currentStepIndex < 1 ? 'config' : catagoryNames[steps[currentStepIndex - 1].type[0]]}})</button>
        <span class="category-name">{{catagoryNames[currentStep.type[0]]}}</span>
        <button (click)="nextStep()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT' | translate}} ({{currentStepIndex === steps.length - 1 ? 'finish' : catagoryNames[steps[currentStepIndex + 1].type[0]]}})</button>
      </div>
      <p class="help">
        {{'SECTIONS.VAULT_CLEANER.vault-cleaner.REVIEW_SELECTED' | translate: {weaponType: catagoryNames[currentStep.type[0]]} }}<br><br>
        <ng-container *ngIf="currentStep.category === 'weapon'">
          <b>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.SCORE_DISPLAYED_AS' | translate}} <fa-icon (click)="openScoreModale()" [icon]="['fas', 'info-circle']"></fa-icon></b>
          <app-checkbox [ngModel]="scoreDisplayType.type === 'percentage'" (change)="updateDisplayType('percentage')">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.PERCENTAGE' | translate}}</app-checkbox>
          <app-checkbox [ngModel]="scoreDisplayType.type === 'rating'" (change)="updateDisplayType('rating')">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.RATINGS' | translate}}</app-checkbox><br>
          <b>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DISPLAY_POLYVALENT' | translate}} <fa-icon [icon]="['fas', 'info-circle']"></fa-icon></b>
          <app-checkbox [(ngModel)]="scoreDisplayType.showPoly" (change)="updateShowPoly(scoreDisplayType.showPoly)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DISPLAY_POLYVALENT' | translate}}</app-checkbox>
        </ng-container>
      </p>

      <div class="banner">
        <a href="https://pl.go-ga.me/irp7djkg" target="_blank" rel="nofollow">
          <img src="assets/Raid_2.png" alt="Raid Shadow Legends Anniversary">
        </a>
      </div>

      <div class="step-explorer">
        <div class="rules">
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.KEEP' | translate}}</span>
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.REMOVE' | translate}}</span>
        </div>
        <app-video-ad ad="video-vault-mobile" mediaQuery="(max-width: 850px)"></app-video-ad>
        <ng-container *ngFor="let items of currentStep.items | keyvalue; let index = index">
          <div class="step-row" >
            <div class="row-item-header" *ngIf="currentStep.category === 'weapon'">
              <span class="name" *ngIf="!config.weapons.groupElements">{{items.key | itemName: 'DestinyInventoryItemDefinition' }}</span>
              <span class="name" *ngIf="config.weapons.groupElements && !config.weapons.groupArchetypes">{{items.key | itemName: 'DestinyDamageTypeDefinition' }}</span>
              <span class="name" *ngIf="config.weapons.groupElements && config.weapons.groupArchetypes">{{items.key.split('.')[0] | itemName: 'DestinyInventoryItemDefinition' }} + {{items.key.split('.')[1] | itemName: 'DestinyDamageTypeDefinition' }}</span>
              <div class="row-actions">
                <button class="row-action-btn" (click)="rearrangeRow('remove-all', items.value)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.TRASH_ALL' | translate}}</button>
                <button class="row-action-btn" (click)="rearrangeRow('poly', items.value)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.BEST_POLY' | translate}}</button>
                <button class="row-action-btn" (click)="rearrangeRow('pvp', items.value)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.BEST_PVP' | translate}}</button>
                <button class="row-action-btn" (click)="rearrangeRow('pve', items.value)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.BEST_PVE' | translate}}</button>
                <button class="row-action-btn" (click)="rearrangeRow('keep-all', items.value)">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.KEEP_ALL' | translate}}</button>
              </div>
            </div>
            <div class="row-item-header" *ngIf="currentStep.category === 'armor'">
              <ng-container *ngIf="!config.armor.groupByBuild && !config.armor.groupByArtifice">
                <span class="name" *ngIf="!config.armor.groupExotics">{{('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_PIECE_TYPE.' + items.key | translate)}}</span>
                <span class="name" *ngIf="config.armor.groupExotics">{{ items.key < 100 ? (('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_PIECE_TYPE.' + items.key) | translate) : (items.key | itemName: 'DestinyInventoryItemDefinition')}}</span>
              </ng-container>
              <ng-container *ngIf="config.armor.groupByBuild || config.armor.groupByArtifice">
                <span class="name" *ngIf="!config.armor.groupExotics">{{('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_PIECE_TYPE.' + items.key.split('.')[0]) | translate}}</span>
                <span class="name" *ngIf="config.armor.groupExotics">{{ items.key.split('.')[0] < 100 ? (('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_PIECE_TYPE.' + items.key.split('.')[0]) | translate) : (items.key.split('.')[0] | itemName: 'DestinyInventoryItemDefinition')}}</span>
                <div class="row-actions">
                  <span class="build-name" *ngIf="items.key.split('.')[1]">{{('SECTIONS.VAULT_CLEANER.vault-cleaner.BUILD_TYPE.' + items.key.split('.')[1]) | translate}}</span>
                  <!-- <span class="build-name" *ngIf="!items.key.split('.')[1]">{{('SECTIONS.VAULT_CLEANER.vault-cleaner.ARMOR_PIECE_TYPE.' + items.key.split('.')[0]) | translate}}</span> -->
                  <span class="build-name" *ngIf="items.key.split('.')[2]">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.BUILD_TYPE.ARTIFICE' | translate}}</span>
                </div>
              </ng-container>
            </div>
            <div [dndDropzone]="[items.key]" class="keep items"
                 (dndDrop)="onDrop($event, 'keep', items.key)"
                 [ngClass]="{'dropzone-active': currentDropZone == items.key && fromState === 'remove'}"
            >
              <app-destiny-item
                *ngFor="let item of items.value.keep"
                [iconOnly]="true"
                [inventoryItem]="item"
                [displayScore]="true"
                [scoreDisplayType]="scoreDisplayType.type"
                [dndDraggable]="item"
                [crTooltip]="item.definition"
                [polyvalentScoreOnly]="polyvalentScoresOnly"
                [showPoly]="scoreDisplayType.showPoly"
                [minimumScoreValue]="minimumValueFormControl.valid ? minimumValueFormControl.value : 65"
                [crTooltipOptions]="{type: 'item', instance: item, richPerks: true, rules: item.isWeapon ? weaponRules : armorRules}"
                (dndStart)="onDragStart(item, 'keep', items.key)"
                (dndEnd)="onDragEnd()"
              ></app-destiny-item>
            </div>
            <div class="center"><fa-icon [icon]="['fas', 'exchange-alt']" size="2x"></fa-icon></div>
            <div [dndDropzone]="[items.key]" class="delete items"
                 (dndDrop)="onDrop($event, 'remove', items.key)"
                 [ngClass]="{'dropzone-active': currentDropZone == items.key && fromState === 'keep'}"
            >
              <app-destiny-item
                *ngFor="let item of items.value['remove']"
                [iconOnly]="true"
                [inventoryItem]="item"
                [displayScore]="true"
                [scoreDisplayType]="scoreDisplayType.type"
                [dndDraggable]="item"
                crTooltipType="item"
                [crTooltip]="item.definition"
                [showPoly]="scoreDisplayType.showPoly"
                [polyvalentScoreOnly]="polyvalentScoresOnly"
                [minimumScoreValue]="minimumValueFormControl.valid ? minimumValueFormControl.value : 65"
                [crTooltipOptions]="{type: 'item', instance: item, richPerks: true, rules: item.isWeapon ? weaponRules : armorRules }"
                (dndStart)="onDragStart(item, 'remove', items.key)"
                (dndEnd)="onDragEnd()"
              ></app-destiny-item>
            </div>
          </div>
          <div class="ad" *ngIf="index > 0 && index % 10 === 0">
            <app-ad size="banner" [sizes]="[['728', '90'], ['320', '50'], ['320', '100']]" [bannerId]="'banner-vault-index-' + index" [renderVisibleOnly]="true"></app-ad>
          </div>
        </ng-container>
      </div>

      <div class="header">
        <button (click)="prevStep()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.BACK' | translate}} ({{currentStepIndex < 1 ? 'config' : catagoryNames[steps[currentStepIndex - 1].type[0]]}})</button>
        <span class="category-name">{{catagoryNames[currentStep.type[0]]}}</span>
        <button (click)="nextStep()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT' | translate}} ({{currentStepIndex === steps.length - 1 ? 'finish' : catagoryNames[steps[currentStepIndex + 1].type[0]]}})</button>
      </div>

      <ng-container *ngIf="processing">
        <div class="loader">
          <fa-icon [icon]="['fas', 'spinner']" animation="spin" size="lg"></fa-icon>
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.PROCESSING' | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>


<ng-template #execTemplate>
  <div class="vault-sorting-container">
    <app-side-menu [steps]="steps" [currentStepIndex]="currentStepIndex" [state]="state" [stepNames]="catagoryNames" (categoryChange)="goToStep($event)"></app-side-menu>
    <div class="list-container vault-exec">
      <div class="list-header"><h4>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.FINAL_REVIEW' | translate}}</h4></div>
      <p class="help">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.FINAL_REVIEW_DESC' | translate}}</p>

      <div class="review">
        <div class="summary">
          <p>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DELETION_COUNT' | translate: {count: toRemove.length} }}</p>
          <p>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEW_VAULT_SPACE' | translate: {newSpace: toKeep.length} }}700</p>
        </div>
        <h3>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ITEMS_TO_DELETE' | translate}}</h3>
        <div class="trash">
          <app-destiny-item
            *ngFor="let item of toRemove"
            [iconOnly]="true"
            [inventoryItem]="item"
            [displayScore]="true"
            [polyvalentScoreOnly]="polyvalentScoresOnly"
            crTooltipType="item"
            [scoreDisplayType]="scoreDisplayType.type"
            [showPoly]="scoreDisplayType.showPoly"
            [crTooltip]="item.definition"
            [crTooltipOptions]="{type: 'item', instance: item, richPerks: true }"
          ></app-destiny-item>
        </div>
        <h3>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ITEMS_TO_KEEP' | translate}}</h3>
        <div class="keep">
          <app-destiny-item
            *ngFor="let item of toKeep"
            [iconOnly]="true"
            [inventoryItem]="item"
            [displayScore]="true"
            [scoreDisplayType]="scoreDisplayType.type"
            [showPoly]="scoreDisplayType.showPoly"
            [polyvalentScoreOnly]="polyvalentScoresOnly"
            crTooltipType="item"
            [crTooltip]="item.definition"
            [crTooltipOptions]="{type: 'item', instance: item, richPerks: true }"
          ></app-destiny-item>
        </div>
      </div>
      <p class="config-warning">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.SAFE_WARNING' | translate}}</p>
      <button class="app-btn" (click)="finalProcess()" [disabled]="false && useImportedData" [title]="(useImportedData ? 'SECTIONS.VAULT_CLEANER.vault-cleaner.CANNOT_DO_THIS' : 'SECTIONS.VAULT_CLEANER.vault-cleaner.WONT_DELETE') | translate">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.LOCK_UNLOCK_BTN' | translate}}</button>

      <div class="action-row" *ngIf="!useImportedData">
        <button class="dim-btn" [ngClass]="{'loading': taggingProcess.keep.loading, 'done': taggingProcess.keep.done}" (click)="tagItems('keep')" [disabled]="taggingProcess.keep.loading || taggingProcess.keep.done">
          <img src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e" alt="DIM Logo" height="18" width="18">
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.TAG_KEEP' | translate}}
        </button>
        <button class="dim-btn" [ngClass]="{'loading': taggingProcess.junk.loading, 'done': taggingProcess.junk.done}" (click)="tagItems('junk')" [disabled]="taggingProcess.junk.loading || taggingProcess.junk.done">
          <img src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e" alt="DIM Logo" height="18" width="18">
          {{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.TAG_JUNK' | translate}}
        </button>
      </div>

      <div class="action-row">
        <button class="dim-btn" (click)="copyDimQuery()" [title]="'SECTIONS.VAULT_CLEANER.vault-cleaner.DIM_BTN_HELP' | translate">
          <img src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e" alt="DIM Logo" height="18" width="18">
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.COPY_DIM_QUERY' | translate}}</span>
        </button>
        <button class="dr-btn" [title]="'SECTIONS.VAULT_CLEANER.vault-cleaner.EXPORT_CLEANUP_HELP' | translate" (click)="exportCleanup()">
          <img src="assets/logo/logo.svg" height="42" width="42" alt="DR Logo">
          <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.EXPORT_CLEANUP' | translate}}</span>
        </button>
        <button class="clear-btn" *ngIf="useImportedData" (click)="closeVault()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CLOSE_VAULT' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #lockingTemplate>

  <div class="vault">
    <div class="list-container vault-exec" style="width: 400px; margin: 0 auto">
      <img src="assets/logo/splash.svg">
      <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.PROCESS_LOCKS' | translate}}</span>
      <small>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.PLEASE_WAIT' | translate}}</small>
      <br>
      <div class="progress-row"
           *ngIf="executionState?.counters"
           [class.done]="executionState.counters.keep.total === 0"
           [class.loading]="executionState.counters.keep.processed !== executionState.counters.keep.total"
           [class.waiting]="executionState.counters.keep.total > 0 && executionState.counters.keep.processed === executionState.counters.keep.total"
      >
        <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.LOCKING' | translate}}</span>
        <span class="progress-value">{{executionState.counters.keep.processed}}/{{executionState.counters.keep.total}}</span>
        <fa-icon class="spinner" [icon]="['fas', 'spinner']" spin="true"></fa-icon>
        <fa-icon class="check" [icon]="['fas', 'check']"></fa-icon>
      </div>
      <div class="progress-row"
           *ngIf="executionState?.counters"
           [class.done]="executionState.counters.remove.total === 0"
           [class.loading]="executionState.counters.remove.processed !== executionState.counters.remove.total"
           [class.waiting]="executionState.counters.remove.total > 0 && executionState.counters.remove.processed === executionState.counters.remove.total"
      >
        <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.UNLOCKING' | translate}}</span>
        <span class="progress-value">{{executionState.counters.remove.processed}}/{{executionState.counters.remove.total}}</span>
        <fa-icon class="spinner" [icon]="['fas', 'spinner']" spin="true"></fa-icon>
        <fa-icon class="check" [icon]="['fas', 'check']"></fa-icon>
      </div>
      <div class="progress-row message loading" *ngIf="executionState.state === 'checking'"><span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DOUBLE_CHECK' | translate}}</span></div>
      <div class="progress-row message" *ngIf="executionState.retryCount"><span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.ITEMS_MISSED' | translate}} ({{executionState.retryCount}}/{{MAX_TRIES}})</span></div>
      <br>
      <div class="progress-row message retry" (click)="cancelProcess()">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.STUCK' | translate}}</div>

      <ng-container *ngIf="false; else social"></ng-container>
    </div>
  </div>
</ng-template>


<ng-template #doneTemplate>
  <div class="vault">
    <div class="list-container vault-exec">
      <h2>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.CONGRATULATIONS' | translate}}</h2>
      <ng-container  *ngIf="error">
        <p class="config-warning">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.MAX_TRIES' | translate}}</p>
        <h4 *ngIf="errorItems?.remove?.length">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.UNLOCK_AND_REMOVE' | translate}}</h4>
        <div class="trash" *ngIf="errorItems?.remove?.length">
          <app-destiny-item
            *ngFor="let item of errorItems?.remove"
            [iconOnly]="true"
            [inventoryItem]="item"
            [displayScore]="true"
            [scoreDisplayType]="scoreDisplayType.type"
            [polyvalentScoreOnly]="polyvalentScoresOnly"
            crTooltipType="item"
            [showPoly]="scoreDisplayType.showPoly"
            [crTooltip]="item.definition"
            [crTooltipOptions]="{type: 'item', instance: item, richPerks: true }"
          ></app-destiny-item>
        </div>
        <h4 *ngIf="errorItems?.keep?.length">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.LOCK_AND_KEEP' | translate}}</h4>
        <div class="keep" *ngIf="errorItems?.keep?.length">
          <app-destiny-item
            *ngFor="let item of errorItems?.keep"
            [iconOnly]="true"
            [inventoryItem]="item"
            [polyvalentScoreOnly]="polyvalentScoresOnly"
            [displayScore]="true"
            [scoreDisplayType]="scoreDisplayType.type"
            [showPoly]="scoreDisplayType.showPoly"
            crTooltipType="item"
            [crTooltip]="item.definition"
            [crTooltipOptions]="{type: 'item', instance: item, richPerks: true }"
          ></app-destiny-item>
        </div>
        <hr style="width: 100%">
      </ng-container>

      <p>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT_STEPS.HERES' | translate}}</p>
      <ul>
        <li>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT_STEPS.1' | translate}}</li>
        <li>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT_STEPS.2' | translate}}</li>
        <li>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT_STEPS.3' | translate}}</li>
      </ul>
      <p class="config-warning">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.NEXT_STEPS.DONOT' | translate}}</p>
      <button class="app-btn" (click)="state = 'config'">{{'SECTIONS.VAULT_CLEANER.vault-cleaner.DONE' | translate}}</button>
      <ng-container *ngIf="false; else social"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loader">
    <fa-icon [icon]="['fas', 'spinner']" animation="spin" size="lg"></fa-icon>
    <span>{{'SECTIONS.VAULT_CLEANER.vault-cleaner.LOADING_INVENTORY' | translate}}</span>
  </div>
</ng-template>
<ng-template #notLoggedIn>
  <div class="list-container">
    <div class="list-header">
      <h4>{{'COMMON.NOT_LOGGED' | translate}}</h4>
    </div>
    <p class="text-info">{{'COMMON.LOG_HELP_VAULT' | translate}}</p>
    <span>{{'COMMON.OR' | translate}}</span>
    <button class="app-btn" (click)="importVault()"><fa-icon [icon]="['fas', 'upload']"></fa-icon> {{'SECTIONS.VAULT_CLEANER.vault-cleaner.ACTIONS.IMPORT_VAULT' | translate}}</button>
  </div>
</ng-template>


<ng-template #social>
  <app-social></app-social>
</ng-template>
